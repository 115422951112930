import axios from "axios";
import authHeader from "./auth-header";

//const API_URL = "http://localhost:8080/api/test/";
const API_URL = process.env.REACT_APP_BACK_URL as string + "/";

export const getPeoplePerson = () => {
    console.log(authHeader());
    return axios.get(API_URL + "people/getperson/" + "451533d5-d8d5-4a11-9c7b-eb9f14e1a32f", { headers: authHeader() }); // for test mcs people
  };



