import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Upload } from '../upload-photo.component';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteAlbumAsync, getAlbumAsync } from '../../../../services/photo.service';
import { Album } from '../album.models';
import AppLayout from '../../../layout/AppLayout';
import './album.component.css';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Container from '@mui/material/Container';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#212121',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fafafa',
    },
  },
});

export default function AlbumWithPhoto() {
  const [queryParams, setQueryParams] = useSearchParams();
  const [album, setAlbum] = useState<Album>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("This is an album component");
    loadData();
  },[]);

  const loadData = async () => {
    const id = queryParams.get("id") as string;
    getAlbumAsync(id).then(
      (rcvData) => {
        setAlbum(rcvData);
      },
      (error) => {
          console.log(error);
      }
    );
  };

  const returnToAlbumList = () =>{
    navigate('/Albums');
  };

  const handleDeleteAlbum = () => {
    setOpenConfirmDialog(true);
  }

  const handleSubmitConfirmDialog = async () => {
    if(album?.id){
      deleteAlbumAsync(album.id).then(
        () => {
          handleCloseConfirmDialog();
          returnToAlbumList();
        },
        (error) => {
            console.log(error);
        }
      );
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const SmallHeaderText = styled('div')({
    fontFamily: 'Acme',
    fontSize: 30,
    color: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '0.5%',
    paddingBottom: '3%',
  });

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <Container component="main" maxWidth="xl">
          <Box sx={{ width: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography component="h5" variant="h6">
              {album?.name}
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Box m={1} sx={{ width: "20%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Button variant="outlined" sx={{ mt: 1, mb: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => returnToAlbumList()} >Назад</Button>
              {album && album.images && album.images.length > 0 ? (
                <Button variant="contained" sx={{ mt: 0, mb: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => navigate('/SlideShowPhoto?albumId=' + album?.id) } >Слайдшоу</Button>
              ) : 
              (
                <Button variant="contained" sx={{ mt: 0, mb: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => handleDeleteAlbum()}>Удалить альбом</Button>
              )}
              <Upload onComplete={() => loadData()} />
            </Box>
            <Box sx={{ width: "80%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {album ? ( // проверяем, определен ли album
                  <ImageList variant="masonry" cols={3} gap={8} >
                    {(((album as Album).images)).map((item) => (
                      <ImageListItem key={item.id}>
                        {
                          <img 
                            style={{ cursor: 'pointer' }}
                            srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.url}?w=248&fit=crop&auto=format`}
                            alt={item.name} 
                            loading='lazy' 
                            onClick={function() {
                              console.log(item.id)
                              navigate(`/Photo?id=${item.id}&albumId=${album.id}`);
                            }}
                          />
                        }
                      </ImageListItem>
                    ))}

                  </ImageList>
                ) : (
                  <p>Загрузка...</p>
                )}
              </div>
            </Box>
          </Stack>
        </Container>
      </AppLayout>
      <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Вы уверены что хотите удалить альбом?"}
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmitConfirmDialog} autoFocus>
                Да
              </Button>
              <Button onClick={handleCloseConfirmDialog}>Нет</Button>
            </DialogActions>
        </Dialog>
    </ThemeProvider>
  );
}