import { NavLink } from 'react-router-dom';
import { Button, Grid, Link } from '@mui/material';

import { getCurrentUser } from '../../../../services/auth.service';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIsLoggedIn } from '../../../../services/auth.service';

import { logout } from '../../../../services/auth.service';
import React from 'react';


interface User {
    displayName: unknown;
    login: string;
    username: string;
    password: string;
    email: string;
}

const linkStyles = {
    color: 'white',
    fontFamily: 'Acme',
    fontWeight: 100,
    fontSize: 15,
    textDecoration: 'none',
    marginX: 2,
};

const HeaderMenuLogin = () => {
    const handleLogout = async () => {
        logout(); // вызов функции logout для очистки данных пользователя из localStorage

        window.location.href = "/";
        console.log("Токен и пользователь успешно удалены");
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                bgcolor: '#000000',
                height: 57,
                paddingX: '10%',
                display: 'flex',
            }}
        >
            <Link component={NavLink} to='/' variant="button" sx={linkStyles}>О портале</Link>
            <Link component={NavLink} to='/' variant="button" sx={linkStyles}>Блоги</Link>
            <Link component={NavLink} to='/Profile' variant="button" sx={linkStyles}>Мой профиль</Link>
            <Link component={NavLink} to='/MyFamily' variant="button" sx={linkStyles}>Моя семья</Link>
            <Link component={NavLink} to='/Graph' variant="button" sx={linkStyles}>Показать семейное дерево</Link>
            <Link component={NavLink} to='/Albums' variant="button" sx={linkStyles}>Мои альбомы</Link>
            <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                    ...linkStyles,
                    backgroundColor: 'black',
                    '&:hover': {
                        backgroundColor: 'black', // Чтобы фон не менялся
                    }
                }}
            >
                Выйти
            </Button>
        </Grid>
    );
}

export default HeaderMenuLogin;
