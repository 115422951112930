import axios from "axios";
import authHeader from "./auth-header";

//const API_URL = "https://localhost:7106" + "/people";
const API_URL = process.env.REACT_APP_BACK_URL as string + "/people";

export const getFamilies = (userID:string) => {
    return axios.get(API_URL + "/families/" + userID, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
};

export const addFamily= (name:string, description:string, userID:string) => {
    return axios
    .post(API_URL + "/addfamily", {
        name,
        description,
        userID
    }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export const getPersons = (familyGuid:string) => {
    return axios.get(API_URL + "/getFamilyWithPersons/" + familyGuid, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
};

export const addPerson = (
    familyGuid:string, 
    firstName:string,
    middleName:string, 
    lastName:string, 
    dateOfBirth:string, 
    dateOfDeath:string|null, 
    gender:number, 
    description:string) => {
    return axios
    .post(API_URL + "/addperson/" + familyGuid, {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        dateOfDeath,
        gender,
        description
    }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export const updatePerson = (
    familyGuid:string, 
    firstName:string,
    middleName:string, 
    lastName:string, 
    dateOfBirth:string, 
    dateOfDeath:string|null, 
    gender:number, 
    description:string,
    ImageId:string) => {
    return axios
    .put(API_URL + "/updatePerson/" + familyGuid, {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        dateOfDeath,
        gender,
        description,
        ImageId
    }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export const removeFamilyService = (familyGuid:string) => { 
    return axios
    .delete(API_URL + "/removefamily/" + familyGuid, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export const removePersonService = (personID:string) => { 
    return axios
    .delete(API_URL + "/removeperson/" + personID, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

export const getPersonWithRelationsService = (personGuid:string) => {
    return axios.get(API_URL + "/getPersonWithRelations/" + personGuid, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
};

export const getFamilyForTreeService = (familyGuid:string) => {
    return axios.get(API_URL + "/getFamilyForTree/" + familyGuid, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
};

export const addRelationService = (
    personSourceGuid:string, 
    personDestGuid:string,
    relationTypeName:string) => {
    return axios
    .post(API_URL + "/addrelation", { 
        personSourceGuid,
        personDestGuid,
        relationTypeName
    }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};