import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lime, blue } from '@mui/material/colors';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createAlbumAsync, getAlbums, getAlbumsAsync } from '../../../../services/photo.service';
import { Album } from '../album.models';
import AppLayout from '../../../layout/AppLayout';
import Sidebar from '../../../sidebar/Sidebar';
import { Dialog, DialogTitle, Divider, DialogContent, TextField, DialogActions, ButtonGroup } from '@mui/material';
import { User } from '../../../../types/user.type';
import { getCurrentUser } from '../../../../services/auth.service';
import { useAppDispatch, useAppSelector } from '../../../../store';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const theme = createTheme({
    palette: {
      primary: blue,
      secondary: {
        main: '#212121',
        light: '#F5EBFF',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#fafafa',
      },
    },
});

const buttonTheme = createTheme({
  typography: {
    fontFamily: 'Acme', // Указываем шрифт Acme
  },
  palette: {
    primary: blue,
    secondary: {
        main: '#212121',
        light: '#F5EBFF',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#fafafa',
    },
  },
});


export default function Albums() {
  const [albums, setAlbums] = useState<Album[]>([]);
  const dispatch = useAppDispatch();
  const user:User = getCurrentUser();

  let navigate: NavigateFunction = useNavigate();
  const [open, setOpen] = useState(false);
  const [albumName, setAlbumName] = useState("");

  const handleClickOpen = () => {
    setAlbumName("");
    setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  const handleAlbumNameChange = (event:any) => {
    setAlbumName(event.target.value);
  };

  const handleSubmit = async () => {
    const name = albumName as string;
    if(name.trim() == ''){
      console.error("Ошибка добавления альбома: пустые параметры");
      return;
    }
    createAlbumAsync(name, [], user.id)
    .then(
      () => {
        getAlbumsFromApi();
        handleClose();
      },
      (error) => {
          console.log(error.cause);
          handleClose();
      }
    );
  };

  const getAlbumsFromApi = () => {
    getAlbumsAsync(user.id).then(
        (rcvData) => {
          setAlbums(rcvData);
        },
        (error) => {
            console.log(error);
        }
    );
};

  useEffect(() => {
    const fetchData = async () => {
      getAlbumsFromApi();
    }

    fetchData();
  },[]);


  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CssBaseline />
        <Container component="main" maxWidth="xl">
          <Box sx={{ width: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography component="h5" variant="h6">
              Ваши альбомы
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Box m={1} sx={{ width: "20%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={handleClickOpen}>Создать альбом</Button>
            </Box>
            <Box sx={{ width: "80%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Container sx={{ py: 8 }} maxWidth="md">
              {}
                <Grid container spacing={4}>
                {
                  albums?.map((album) => (
                    <Grid item key={album.id} xs={12} sm={6} md={4}>
                      <Card
                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                      >
                        <CardMedia
                          component="div"
                          sx={{
                            // 16:9
                            pt: '56.25%',
                          }}
                          image="https://source.unsplash.com/random?wallpapers"
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {album.name}
                          </Typography>
                          <Typography>
                            Описание
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small" onClick={() => { 
                              console.log(album)
                              var id = album.id;
                              navigate(`/AlbumWithPhoto?id=${id}`);
                              }}>View</Button>
                          <Button size="small">Edit</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Stack>
        </Container>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>Новый альбом</DialogTitle>
          <Divider />
          <DialogContent >
            <Box component="form" sx={{'& .MuiTextField-root': { m: 1 }}} noValidate autoComplete="off">
              <TextField name="familyName" required fullWidth id="familyName" label="Введите название альбома" value={albumName} onChange={handleAlbumNameChange} autoFocus/>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} sx={{ mt: 3, mb: 2 }} color="secondary">Сохранить</Button>
            <Button onClick={handleClose} sx={{ mt: 3, mb: 2 }} color="secondary">Отмена</Button>
          </DialogActions>
        </Dialog>
      </AppLayout>
    </ThemeProvider>
  );
}
