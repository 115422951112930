import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { deletePhotoAsync, getImageAsync, updatePhotoAsync } from '../../../../services/photo.service';
import { Image } from '../album.models';
import AppLayout from '../../../layout/AppLayout';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { User } from '../../../../types/user.type';
import { getCurrentUser } from '../../../../services/auth.service';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getFamilies, getPersons, updatePerson } from '../../../../services/people.service';
import { FamilyModel } from '../../../../models/people/FamilyModel';
import { FamilyWithPersonsModel } from '../../../../models/people/FamilyWithPersonsModel';
import { PersonForFamily } from '../../../../models/people/PersonForFamily';
import { blue } from '@mui/material/colors';
import Container from '@mui/material/Container';
import DialogContentText from '@mui/material/DialogContentText';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#212121',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fafafa',
    },
  },
});

export default function Photo() {
  const [queryParams] = useSearchParams();
  const [image, setImage] = useState<Image>();
  const [editCaption, setEditCaption] = useState<Boolean>(false);
  const [photoCaption, setPhotoCaption] = useState<string>("");
  const id = queryParams.get("id") as string;
  const albumId = queryParams.get("albumId") as string;
  let navigate: NavigateFunction = useNavigate();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [familyList, setFamilyList] = useState<FamilyModel[]>([]);
  const [currentPersonToAdd, setCurrentPersonToAdd] = useState<PersonForFamily>();
  const [family, setFamily] = useState<FamilyWithPersonsModel>();

  const user:User = getCurrentUser();

  const getFamiliesFromApi = () => {
    getFamilies(user.id).then(
        (rcvData) => {
            setFamilyList(rcvData);
        },
        (error) => {
            console.log(error);
        }
    );
  };

  function getPersonsFromApiAndReturn(familyGuid:string) {
    return getPersons(familyGuid)
  };

  const handleChangeCaption = () => {
    setEditCaption(true);
  }

  const handleDeletePhoto = () => {
    setOpenConfirmDialog(true);
  }

  const handleSaveCaption = () => {
    if(image?.id && photoCaption != "" && photoCaption != image.imageText){
      updatePhotoAsync(
        image.id,
        image.name,
        photoCaption)
    }

    setEditCaption(false);
  }

  const handleClickOpen = async () => {
    await getFamiliesFromApi();
    setAlbumName("");
    setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
};

  const handleSubmit = async () => {
    console.log(currentPersonToAdd)
    //add logic to add photo to component
    if(currentPersonToAdd?.id && image?.id){
      updatePerson(
        currentPersonToAdd?.id,
        currentPersonToAdd?.firstName,
        currentPersonToAdd?.middleName,
        currentPersonToAdd?.lastName,
        currentPersonToAdd?.dateOfBirth,
        currentPersonToAdd?.dateOfDeath ?? null,
        0,
        '',
        image.id)
    }

    handleClose();
  };

  const handleSubmitConfirmDialog = async () => {
    if(image?.id){
      deletePhotoAsync(image.id).then(
        () => {
          handleCloseConfirmDialog();
          navigate('/AlbumWithPhoto?id=' + albumId);
        },
        (error) => {
            console.log(error);
        }
      );
    }
  };

  const handlePhotoCaptionChange = (event:any) => {
    let val = event.target.value;
    if(!val)
      return;

    setPhotoCaption(val);
};

  useEffect(() => {
    loadData();

    console.log(image)
  },[]);

  useEffect(() => {
    console.log(family)
  },[family]);

  const loadData = async () => {
    const user:User = getCurrentUser();
    getImageAsync(id).then(
      (rcvData) => {
        setImage(rcvData);
        setPhotoCaption(rcvData?.imageText);
      },
      (error) => {
          console.log(error);
      }
    );
  };

  return (
    <AppLayout>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
          <Stack direction="row" spacing={2}>
            <Box m={1} sx={{ width: "20%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Stack direction="column" spacing={0}>
              <Button variant="outlined" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => navigate('/AlbumWithPhoto?id=' + albumId)} >Назад</Button>
              <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={handleClickOpen}>Сменить фото для члена семьи</Button>
              {editCaption ? (
                  <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="primary" onClick={handleSaveCaption}>Сохранить описание</Button>
                )
                :
                (
                  <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={handleChangeCaption}>Изменить описание</Button>
                )
              }
              <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={handleDeletePhoto}>Удалить фото</Button>
            </Stack>
            </Box>
            <Box sx={{ width: "80%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {image ? (
                <div>
                  <>
                    <img src={image.url} alt={image.name} style={{width: "100%", objectFit: "contain"}} />
                    <InputLabel htmlFor="filled-multiline-static" sx={{ m: 1 }}>Описание фотографии</InputLabel>
                    <TextField
                      id="outlined-required"
                      fullWidth 
                      sx={{ m: 1 }}
                      multiline
                      rows={5}
                      defaultValue="Это описание"
                      variant="filled"
                      value={photoCaption}
                      onChange={handlePhotoCaptionChange}
                      InputProps={{
                        readOnly: !editCaption,
                      }}
                    />
                  </>
                </div>
                ) : 
                (
                  <p>Загрузка...</p>
                )
              }
            </Box>
          </Stack>
        </Container>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
              <DialogTitle>Смена фотокарточки для члена семьи</DialogTitle>
              <Divider />
              <DialogContent >
              <Box component="form" sx={{'& .MuiTextField-root': { m: 1 }}} noValidate autoComplete="off">
                <FormControl fullWidth style={{display: "flex"}}>    
                  <InputLabel id="demo-simple-select-label">Укажите семью</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="family"
                      label="Укажите семью"
                      required
                      onChange={async (newValue) => {
                        setFamily(await getPersonsFromApiAndReturn(newValue.target.value as unknown as string) as unknown as FamilyWithPersonsModel)}
                      }
                    >
                      {
                        familyList.map((value) => (
                          <MenuItem value={value.id}>{value.name}</MenuItem>
                        ))
                      }
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{display: "flex", margin: "50px 0px 0px 0px"}} >
                {
                    family?.id && (
                      <>
                        <InputLabel id="demo-simple-select-label-second">Укажите персону</InputLabel>
                        <Select
                          labelId="demo-simple-select-label-second"
                          id="person"
                          label="Укажите персону"
                          required
                          onChange={
                            (newValue) => {
                              family.personList.forEach((person: PersonForFamily) =>{
                                if(person.id == newValue.target.value)
                                setCurrentPersonToAdd(person)
                              })
                            }
                          }
                        >
                          {
                            family?.personList.map((value) => (
                              <MenuItem value={value.id}>{value.lastName + ' ' + value.firstName + ' ' + value.middleName}</MenuItem>
                            ))
                          }
                        </Select>
                      </>
                      
                    )
                  }
                </FormControl>
              </Box>
            </DialogContent>
              <DialogActions>
              <Button onClick={handleSubmit}>Выбрать</Button>
              <Button onClick={handleClose}>Отмена</Button>
            </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Вы уверены что хотите удалить фото?"}
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmitConfirmDialog} autoFocus>
                Да
              </Button>
              <Button onClick={handleCloseConfirmDialog}>Нет</Button>
            </DialogActions>
      </Dialog>
      </ThemeProvider>
    </AppLayout>
  );
}