import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, ThemeProvider, Tooltip, Zoom, createTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addRelationService, getPersonWithRelationsService } from "../../../services/people.service";
import AppLayout from "../../layout/AppLayout";
import { blue } from "@mui/material/colors";
import Container from '@mui/material/Container';

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: {
            main: '#212121',
            light: '#F5EBFF',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fafafa',
        },
    },
});

interface PersonWithRelations{
    personGuid:string;
    name:string;
    gender:number;
    dateOfBirth:string;
    parents: PersonRelations[];
    siblings: PersonRelations[];
    spouses: PersonRelations[];
    children: PersonRelations[];
}

interface PersonRelations{
    personGuid:string;
    personName:string;
}

interface RelativePersonToAdd{
    personSourceGuid:string;
    personDestGuid:string;
    relationTypeName:string;
}

const PersonPassport = () =>
{
    const navigate = useNavigate();
    const personGuid = useSelector((gs:any) => gs.family.currentPersonGuid);
    const personList = useSelector((gs:any) => gs.family.currentPersonsFroFamily);
    const [personFromApi, setpersonFromApi] = useState<PersonWithRelations>();
    const [open, setOpen] = useState(false);
    const [relativeToAdd, setRelativeToAdd] = useState<RelativePersonToAdd>();

    useEffect(() => {
        if(!personGuid || personGuid.trim() == ''){
            console.error('Не могу загрузить человека, он не выбран');
            return;
        }
        getPersonsFromApi(personGuid);
    }, []);

    const returnToPersonList = () =>{
        navigate('/FamilyPassport');
    }

    const handleAddDialog = () => {
        if(!personGuid || personGuid.trim() == ''){
            console.error('Не могу загрузить человека, он не выбран');
            return;
        }
        const relative = {} as RelativePersonToAdd;
        setRelativeToAdd(relative);
        if(personList.length > 0){
            setOpen(true);
        }
    }

    const getPersonsFromApi = (personGuid:string) =>{
        getPersonWithRelationsService(personGuid).then(
            (respData) => {
                setpersonFromApi(respData);
                console.log(respData);
            },
            (error) => {
                console.log(error.cause);
            }
        )
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAddRelation = () => {
        const relative = relativeToAdd;
        if(!relative){
            console.log('cant find relative');
            return;
        }
        relative.personSourceGuid = personGuid;
        const personSourceGuid = relative.personSourceGuid;
        const personDestGuid = relative.personDestGuid;
        const relationTypeName = relative.relationTypeName;

        console.log(relative);
        addRelationService(
            relative.personSourceGuid,
            relative.personDestGuid,
            relative.relationTypeName)
        .then(
            () => {
                const personWithRelation = {} as PersonWithRelations;
                setpersonFromApi(personWithRelation);
                getPersonsFromApi(personGuid);
                const relativePerson = {} as RelativePersonToAdd;
                setRelativeToAdd(relativePerson);
                setOpen(false);
            },
            (error) => {
                console.log(error.cause);
            }
        );
    };

    const handleRelationChange = (event:any) => {
        const relative = relativeToAdd;
        if(!event || !relative){
            return;
        }
        relative.relationTypeName = event.target.value;
        setRelativeToAdd(relative);
    };

    const handleRelativePersonChange = (event:any, value:any) => {
        const relative = relativeToAdd;
        if(!event || !value || !relative) {
            return;
        }
        relative.personDestGuid = value.personId;
        setRelativeToAdd(relative);
    };

    if(personFromApi?.children) {
        return (   
            <AppLayout>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xl">
                        <Box sx={{ width: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Typography component="h5" variant="h6">
                                    {personFromApi.name}
                                </Typography>
                        </Box>
                        <Stack direction="row" spacing={2}>
                            <Box m={1} sx={{ width: "20%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Button variant="outlined" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => returnToPersonList()} >Назад</Button>
                                <Button variant="contained" sx={{ mt: 1, height: '100%', width: '100%'}} color="secondary" onClick={() => handleAddDialog()}>Добавить родственную связь</Button>
                            </Box>
                            <Box sx={{ width: "80%", height: "100%", marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Stack direction="column" sx={{ marginTop: 2 }}>
                                    <Stack direction="row" spacing={1} sx={{ marginTop: 0 }}>
                                        <Typography><b>Родители:</b></Typography>
                                        <Typography>{personFromApi.parents.length > 0 ? personFromApi.parents.map((personRel:PersonRelations) => {return <>{personRel.personName}; </>}) : "нет"}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                                        <Typography><b>Дети:</b></Typography>
                                        <Typography>{personFromApi.children.length > 0 ? personFromApi.children.map((personRel:PersonRelations) => {return <>{personRel.personName}; </>}) : "нет"}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                                        <Typography><b>Сестры/Братья:</b></Typography>
                                        <Typography>{personFromApi.siblings.length > 0 ? personFromApi.siblings.map((personRel:PersonRelations) => {return <>{personRel.personName}; </>}) : "нет"}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                                        <Typography><b>Супруг/Супруга:</b></Typography>
                                        <Typography>{personFromApi.spouses.length > 0 ? personFromApi.spouses.map((personRel:PersonRelations) => {return <>{personRel.personName}; </>}) : "нет"}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Container>
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Новый родственник для {personFromApi.name}</DialogTitle>
                        <Divider />
                        <DialogContent >
                        <Stack spacing={1}>
                            <Autocomplete
                                id="combo-box-choose-relative"
                                disableClearable
                                options={personList.map((person:any) => ({label: person.name, personId: person.id}))}
                                onChange={handleRelativePersonChange}
                                renderInput={(params) => <TextField {...params} id={params.id} label="Выберите родственника" />}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Укажите тип родственной связи</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="gender"
                                    label="Укажите тип родственной связи"
                                    required
                                    onChange={(newValue) => handleRelationChange(newValue)}
                                >
                                    <MenuItem value='Parent'>Родитель</MenuItem>
                                    <MenuItem value='Child'>Ребенок</MenuItem>
                                    <MenuItem value='Sibling'>Сестра/Брат</MenuItem>
                                    <MenuItem value='Spouse'>Супруг/супруга</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleAddRelation}>Сохранить</Button>
                        <Button onClick={handleClose}>Отмена</Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
            </AppLayout>
        );
    }
    else{
        return (<>Загрузка...</>)
    }
    
}

export default PersonPassport;