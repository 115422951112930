import axios from "axios";
import { setAlbums } from "../reducers/photoSlice";
import { Dispatch } from "react";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_URL as string + "/photo";

export const createAlbumAsync = (name: string, imageIds: number[], userId: string) => {
    return axios.post(API_URL + "/Album/Create", {
        name,
        imageIds,
        userId
    }, { headers: authHeader() })
    .then((response) => {
      console.log(response.data);
    });
};

// @ts-ignore
export const getAlbumsAsync = (userId: string) => {
    let res =  axios.get(API_URL + "/GetAlbums/" + userId, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
    return res;
 };

export const getAlbumAsync = (id: string) => {
    let res =  axios.get(API_URL + "/GetAlbum/" + id, { headers: authHeader() })
        .then((response) => {
            console.log(response);
            return response.data;      
        });
    return res;
};

export const deleteAlbumAsync = (id: string) => {
  let res =  axios.delete(API_URL + "/Album/Delete/" + id, { headers: authHeader() })
      .then((response) => {
          console.log(response);
          return response.data;      
      });
  return res;
};

export const getImageAsync = (id: string) => {
  console.log(API_URL + "/Image/Get/" + id);
    let res = axios.get(API_URL + "/Image/Get/" + id, { headers: authHeader() })
        .then((response) => {
          console.log(response);
          return response.data;      
      });
      return res;
};

export const createPhotoAsync = (name: string, 
  source: string, 
  albumId: string) => {
    return axios.post(API_URL + "/Image/Create", {
      name,
      source,
      albumId,
    }, { headers: authHeader() })
.then((response) => {
  console.log(response.data);
});
};

export const updatePhotoAsync = (
  imageGuid: string,
  name: string, 
  imageText: string) => {
    return axios.put(API_URL + "/Image/Update/" + imageGuid, {
      name,
      imageText
      }, { headers: authHeader() })
      .then((response) => {
        console.log(response.data);
      });
};

export const deletePhotoAsync = (
  imageGuid: string) => {
    return axios.delete(API_URL + "/Image/Delete/" + imageGuid, { headers: authHeader() })
      .then((response) => {
        console.log(response.data);
      });
};

export const getAlbums = () => {
  const data = localStorage.getItem("albums");
  if (data) return JSON.parse(data);

  return null;
};

