import { getIsLoggedIn, getCurrentUser } from "../../services/auth.service";
import { User } from "../../types/user.type";
import { getPeoplePerson } from "../../services/user.service";

const Usercontent = () => {
    const user:User  = getCurrentUser();
    const lgIn:boolean = getIsLoggedIn();  

    const css = {
        padding: '10px',
        border: '5px solid blue'
    };

    console.log("User isLoggedIn from storage = " + lgIn);
    console.log("User token from storage = " + user.token);

    if(lgIn == true)
        console.log("User ON platform");
    else 
        console.log("User OFF platform");

    getPeoplePerson();

    return <div style={css}>Это страница 1</div>



};

export default Usercontent;