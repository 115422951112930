import { Button } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createPhotoAsync, getAlbumsAsync } from "../../../services/photo.service";
import { Image } from './album.models';
import { getCurrentUser } from "../../../services/auth.service";
import { User } from "../../../types/user.type";
import { useAppDispatch } from "../../../store";

interface UploaderProps {
  fileType?: string | AcceptedFileType[];
  onComplete: () => void
}

enum AcceptedFileType {
  Text = ".txt",
  Gif = ".gif",
  Jpeg = ".jpg",
  Png = ".png",
  Doc = ".doc",
  Pdf = ".pdf",
}

export const Upload = (props: UploaderProps) => {
  // const [baseImage, setBaseImage] = useState("");
  const [queryParams, setQueryParams] = useSearchParams();
  const dispatch = useAppDispatch();
  
  const { fileType } = props;
  const acceptedFormats: string | AcceptedFileType[] =
    typeof fileType === "string"
      ? fileType
      : Array.isArray(fileType)
      ? fileType?.join(",")
      : AcceptedFileType.Png;
      
      const [selectedFiles, setSelectedFiles] = useState<File | undefined>(
    undefined
  );

  const [selectedFileName, setSelectedFileName] = useState<string>("");

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(event?.target?.files?.[0]);
    if(event?.target?.files?.[0]) {
      getFileName(event?.target?.files?.[0]);
    }
  };

  const onUpload = async () => {
    console.log(selectedFiles);

    let b64 = (selectedFiles as File)?.type.indexOf('image') > -1 ? await readFileDataAsBase64((selectedFiles as File)) as string : '';
    if (b64 != ''){
      b64 = b64.replace('data:', '').replace(/^.+,/, '');
    }

    var item = 
    {
      fileName: selectedFiles?.name,
      base64String: b64,
      //https://base64.guru/converter/decode/image/png to check decoded img
    }
    console.log('selected item = ');
    console.log(item);

    var albumId = queryParams.get("id") as string;
    console.log(albumId);
    createPhotoAsync(item.fileName != undefined ? item.fileName : 'not-named', item.base64String, albumId)
    .then(
        () => 
        {
          setSelectedFiles(undefined);
          const loadData = async () => {
            const user:User = getCurrentUser();
            let albums = getAlbumsAsync(user.id);
           };
          loadData();
          props.onComplete();
        },
        (error) => {
          console.log(error.cause);
      }
    );
    
  };

  const getFileName = (file: File) => {
    if(!file) {
      setSelectedFileName("");
      return;
    }
    if (file.name.length < 25) {
      setSelectedFileName(file.name);
      return;
    }

    setSelectedFileName(file.name.substring(0, 20) + "..." + file.name.substring(file.name.length-4));
  }

  function readFileDataAsBase64(file : File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target?.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}

  return (
    <>
      {!selectedFiles?.name &&  
      (
        <Button variant="contained" color="secondary" component="label" style={{ textTransform: "none" }}>
          <input hidden type="file" accept={acceptedFormats} onChange={handleFileSelect}/><span>Выберите файл для загрузки</span>
        </Button>
      )}
      {selectedFiles?.name && (
        <>
          <span style={{ float: "left" }}>{selectedFileName}</span>
          <Button variant="contained" color="secondary" disabled={!selectedFiles} sx={{ mt: 1, height: '100%', width: '100%'}} onClick={onUpload}>
            Загрузить фото  
          </Button>
          <Button variant="contained" color="secondary" disabled={!selectedFiles} sx={{ mt: 1, height: '100%', width: '100%'}} onClick={(e) => { e.preventDefault(); setSelectedFiles(undefined); }}>
            Очистить
          </Button>
        </>
      )}
    </>
  );
};
